import * as React from 'react';
import { viewAboutMeEnabled, viewPortfolioEnabled, viewGetInTouchEnabled } from '../atoms/Header-Atoms'
import { useRecoilState } from 'recoil';
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';

function Header() {
    // TODO: Refactor, just some initial testing...
    const [enabledAbout, setEnabledAbout] = useRecoilState(viewAboutMeEnabled);
    const [enabledPort, setEnabledPort] = useRecoilState(viewPortfolioEnabled);
    const [enabledTouch, setEnabledTouch] = useRecoilState(viewGetInTouchEnabled);

    const handleChange1 = () => {
        setEnabledAbout(true);
        setEnabledPort(false);
        setEnabledTouch(false);
    };

    const handleChange2 = () => {
        setEnabledAbout(false);
        setEnabledPort(true);
        setEnabledTouch(false);
    };

    const handleChange3 = () => {
        setEnabledAbout(false);
        setEnabledPort(false);
        setEnabledTouch(true);
    };

  return (
    <nav className="nav-elevation">
      <div className="nav-container">
        <div className="nav-left">
        <Button onClick={handleChange1} variant="text"><span className="headerTitle">John Brickner</span></Button>
        </div>
        <div className="nav-right">
          <Stack direction="row" spacing={2}>
            <Button onClick={handleChange1} variant="outlined">About Me</Button>
            <Button onClick={handleChange2} variant="outlined">Portfolio</Button>
            <Button onClick={handleChange3} variant="outlined">Get In Touch</Button>
          </Stack>
        </div>
      </div>
    </nav>
  );
}

  export default Header;