import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
import './components/Header.css';
import './components/MainContent.css'
import './components/Footer.css'
import './components/AboutMe.css'
import './components/Portfolio.css'
import './components/GetInTouch.css'
import './components/ArtCard.css'
import './components/AnimationCard.css'
import './components/GameAppCard.css'
import './components/GraphicDesignCard.css'
import App from './App';
import { RecoilRoot } from 'recoil';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
        <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);