const GraphicDesignData = [
    {
        imagePaths: ["/assets/graphic-design/travuantum-brand-guide.png"],
        imageAlt: "brand guide travel graphic design",
        imageTitle: "Travuantum Brand Guide",
        imageContent: "Brand guide developed for a fictional company in the future around travel via Quantum technology. The full guide can be shown upon request."
    },
    {
        imagePaths: ["/assets/graphic-design/coffee-time.png"],
        imageAlt: "coffee vintage sign",
        imageTitle: "Coffee Time",
        imageContent: "Sign I created in a vintage style with some distressing. Utilized for instructions / reminder on a pour over coffee in a playful way. Done in Adobe Illustrator."
    },
    {
        imagePaths: ["/assets/graphic-design/super-breakout-distressed.png"],
        imageAlt: "super breakout atari video game",
        imageTitle: "Super Breakout Replica",
        imageContent: "Piece I did replicating some of the older video game Super Breakout from Atari. This was created in Adobe Illustrator."
    },
    {
        imagePaths: ["/assets/graphic-design/dice-neon.png"],
        imageAlt: "dnd dice dungeon dragon",
        imageTitle: "DnD Dice",
        imageContent: "DnD dice set with comical caatch phrase using various styles and techniques in Adobe Illustrator."
    },
    {
        imagePaths: ["/assets/graphic-design/all-about-riley-cover.png"],
        imageAlt: "riley cover",
        imageTitle: "All About Riley",
        imageContent: "Book cover for short book about my Whippet Riley. Leveraged a mix of digital and real imagery. Click <a href='https://issuu.com/brixel/docs/week4-final' target='_blank'>here</a> to see the book."
    },
    {
        imagePaths: ["/assets/graphic-design/all-about-riley-film.png"],
        imageAlt: "riley book page",
        imageTitle: "Riley On Film",
        imageContent: "Excerpt from All About Riley book starring my Whippet Riley. Created in Adobe Illustrator."
    },
    {
        imagePaths: ["/assets/graphic-design/faux-business-card.png"],
        imageAlt: "business card",
        imageTitle: "John Brickner Business Card",
        imageContent: "Business card prototype."
    },
    {
        imagePaths: ["/assets/graphic-design/fork-in-the-road.png"],
        imageAlt: "fork in the road connotative",
        imageTitle: "Fork In The Road",
        imageContent: "Connotative play on the title using imagery. Done in pencil."
    },
    {
        imagePaths: ["/assets/graphic-design/key-board.png"],
        imageAlt: "keyboard connotative",
        imageTitle: "Keyboard",
        imageContent: "Another connotative form on the title using a blend of real imagery and digital. Done in Adobe Illustrator."
    },
    {
        imagePaths: ["/assets/graphic-design/orange-explosion.png"],
        imageAlt: "orange explode wax crayon",
        imageTitle: "Orange",
        imageContent: "Part of a series done for class on using a variety of methods to create with. This was an orange done with crayon, wax chips and heat."
    },
    {
        imagePaths: ["/assets/graphic-design/pattern-from-shapes.png"],
        imageAlt: "pattern shape color design",
        imageTitle: "Shape Pattern",
        imageContent: "Pattern created from a variety of shapes and color."
    },
    {
        imagePaths: ["/assets/graphic-design/avenir-typeface.png"],
        imageAlt: "avenir font typeface",
        imageTitle: "Avenir The Typeface",
        imageContent: "Poster for the typeface Avenir done as a class project."
    },
    {
        imagePaths: ["/assets/graphic-design/whippet-typeface-manipulation-black.png"],
        imageAlt: "whippet typeface manipulation",
        imageTitle: "Whippet Typeface",
        imageContent: "Class assignment illustrating typeface manipulation."
    },
    {
        imagePaths: ["/assets/graphic-design/whippet-space-figure-ground.png"],
        imageAlt: "whippet figure-ground",
        imageTitle: "Whippet Figure-Ground",
        imageContent: "Class assignment illustrating figure-ground principle."
    },
    {
        imagePaths: ["/assets/graphic-design/whippet-space.png"],
        imageAlt: "whippet graphic design",
        imageTitle: "Whippet Army",
        imageContent: "Play on some graphic design principles for a class assignment."
    },
    {
        imagePaths: ["/assets/graphic-design/whippet-lemon-juice.jpeg"],
        imageAlt: "whippet painting lemon heat",
        imageTitle: "Whippet At Rest",
        imageContent: "Piece done of a Whippet laying down. I used lemon juice to paint with, then applied heat for the effect seen."
    },
    {
        imagePaths: ["/assets/graphic-design/whippet-majestic-whippet.png"],
        imageAlt: "whippet majestic graphic design",
        imageTitle: "The Majestic Whippet",
        imageContent: "Class assignment combining several concepts to create a faux logo / brand. Done in Adobe Illustrator."
    }
]

export default GraphicDesignData;