import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import PlayCircleOutlineTwoToneIcon from '@mui/icons-material/PlayCircleOutlineTwoTone';
import IconButton from '@mui/material/IconButton';

export default function GameAppCard({itemData}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Grid item>
            <Card sx={{ display: 'flex', maxWidth: 200 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} className="gameAppCardArea">
                    <CardMedia
                        component="img"
                        image={itemData.videoThumb}
                        className="gameAppCardMediaThumb"
                    />
                    <CardContent sx={{ padding: '8px' }} className="gameAppCardContentArea">
                        <Grid container direction="row" alignItems="center">
                            <Grid item style={{ flexGrow: "1" }}>
                                <Typography sx={{ marginLeft: 'auto' }} variant="h7" className="gameAppCardMediaThumbTitle">
                                    {itemData.imageTitle}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleOpen} sx={{ marginRight: 'auto' }}>
                                    <PlayCircleOutlineTwoToneIcon sx={{ fontSize: 30, color: '#e2e2e2' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                        >
                            <Card>
                                <CardMedia 
                                    component="video"
                                    src={itemData.videoPath}
                                    controls
                                    autoPlay
                                />
                            </Card>
                        </Modal>
                    </CardContent>
                </Box>
            </Card>  
        </Grid>
    );
}