import { atom } from "recoil";

export const viewAboutMeEnabled = atom({
    key: 'viewAboutMeEnabled',
    default: true
});

export const viewPortfolioEnabled = atom({
    key: 'viewPortfolioEnabled',
    default: false
});

  export const viewGetInTouchEnabled = atom({
    key: 'viewGetInTouchEnabled',
    default: false
});