const GameAppData = [
    {
        videoPath: "/assets/flash-games-applications/brixel-breakout.mp4",
        videoThumb: "/assets/flash-games-applications/brixel-breakout-thumb.png",
        imageTitle: "Brixel Breakout"
    },
    {
        videoPath: "/assets/flash-games-applications/brixel-bridge-defender.mp4",
        videoThumb: "/assets/flash-games-applications/brixel-bridge-defender-thumb.png",
        imageTitle: "Brixel Defender"
    },
    {
        videoPath: "/assets/flash-games-applications/brixel-blaster.mp4",
        videoThumb: "/assets/flash-games-applications/brixel-blaster-thumb.png",
        imageTitle: "Brixel Blaster"
    },
    {
        videoPath: "/assets/flash-games-applications/brixel-duck-shoot.mp4",
        videoThumb: "/assets/flash-games-applications/brixel-duck-shoot-thumb.png",
        imageTitle: "Brixel Duck Shoot"
    },
    {
        videoPath: "/assets/flash-games-applications/brixel-mastermind.mp4",
        videoThumb: "/assets/flash-games-applications/brixel-mastermind-thumb.png",
        imageTitle: "Brixel Mastermind"
    },
    {
        videoPath: "/assets/flash-games-applications/brixel-maze-hunter.mp4",
        videoThumb: "/assets/flash-games-applications/brixel-maze-hunter-thumb.png",
        imageTitle: "Brixel Maze Hunter"
    },
    {
        videoPath: "/assets/flash-games-applications/brixel-simon-says.mp4",
        videoThumb: "/assets/flash-games-applications/brixel-simon-says-thumb.png",
        imageTitle: "Brixel Simon Says"
    },
    {
        videoPath: "/assets/flash-games-applications/brixel-web-site-a.mp4",
        videoThumb: "/assets/flash-games-applications/brixel-web-site-a-thumb.png",
        imageTitle: "Brixel Site 1"
    },
    {
        videoPath: "/assets/flash-games-applications/brixel-web-site-b.mp4",
        videoThumb: "/assets/flash-games-applications/brixel-web-site-b-thumb.png",
        imageTitle: "Brixel Site 2"
    },
    {
        videoPath: "/assets/flash-games-applications/brixel-web-site-c.mp4",
        videoThumb: "/assets/flash-games-applications/brixel-web-site-c-thumb.png",
        imageTitle: "Brixel Site 3"
    },
    {
        videoPath: "/assets/flash-games-applications/ct-windows-web-site.mp4",
        videoThumb: "/assets/flash-games-applications/ct-windows-web-site-thumb.png",
        imageTitle: "CT Windows Site"
    },
    {
        videoPath: "/assets/flash-games-applications/dragon-innovations-web-site-a.mp4",
        videoThumb: "/assets/flash-games-applications/dragon-innovations-web-site-a-thumb.png",
        imageTitle: "DI 1"
    },
    {
        videoPath: "/assets/flash-games-applications/dragon-innovations-web-site-b.mp4",
        videoThumb: "/assets/flash-games-applications/dragon-innovations-web-site-b-thumb.png",
        imageTitle: "DI 2"
    },
    {
        videoPath: "/assets/flash-games-applications/john-brickner-portfolio.mp4",
        videoThumb: "/assets/flash-games-applications/john-brickner-portfolio-thumb.png",
        imageTitle: "Brickner Portoflio"
    },
    {
        videoPath: "/assets/flash-games-applications/eshare-site.mp4",
        videoThumb: "/assets/flash-games-applications/eshare-site-thumb.png",
        imageTitle: "eShare Site"
    }
]

export default GameAppData;