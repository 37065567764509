import * as React from 'react';
import Fade from '@mui/material/Fade';
import { viewPortfolioEnabled } from '../atoms/Header-Atoms'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import ArtCard from './ArtCard';
import ArtData from './ArtData';
import AnimationCard from './AnimationCard';
import AnimationData from './AnimationData';
import GameAppCard from './GameAppCard';
import GameAppData from './GameAppData';
import GraphicDesignCard from './GraphicDesignCard';
import GraphicDesignData from './GraphicDesignData';
import { useRecoilValue } from 'recoil';

function Portfolio() {
    const enabled = useRecoilValue(viewPortfolioEnabled);
    const [value, setValue] = React.useState('7');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleLaunchApplication = () => {
        window.open("https://john-brickner-recipe-app.pages.dev");
    }

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div id="section-2" className="contentModule">
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs onChange={handleChange} variant="scrollable" scrollButtons="auto">
                            <Tab label="Graphic Design" value="7" />
                            <Tab label="Art" value="4" />
                            <Tab label="Animation" value="5" />
                            <Tab label="Games & Applications" value="6" />
                            <Tab label="Recipe Application" value="2" />
                            <Tab label="React Playground" value="1" />
                            <Tab label="Angular Portfolio" value="3" />
                        </Tabs>
                    </Box>
                    <TabPanel value="7">
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                {GraphicDesignData.map(itemData => (
                                    <GraphicDesignCard itemData={itemData} />
                                ))}
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel className="portfolio-tabpanel" value="1">
                        <iframe className="playground-iframe" src="https://john-brickner-react-playground.pages.dev/" frameBorder={0} scrolling={'no'} title="React Playground"></iframe>
                    </TabPanel>
                    <TabPanel value="2">
                        <Typography>
                            <p>The recipe application is designed to showcase various development skills that are common in software development. The application features singple page architecture, working with UX libraries like MUI, authentication ( Auth0 ), forms, error handling, API integration, backend storage, complex components and more. The button will open a new window / tab.</p>
                        </Typography>
                        <Box sx={{width: '100%'}} textAlign='center'>
                            <Button onClick={handleLaunchApplication} variant='contained'>
                                Launch Applicaton
                            </Button>
                        </Box>
                    </TabPanel>
                    <TabPanel value="3">
                        <iframe className="playground-iframe" src="https://john-brickner-angular.pages.dev/" frameBorder={0} scrolling={'yes'} title="Angular Portfolio Site"></iframe>
                    </TabPanel>
                    <TabPanel value="4">
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                {ArtData.map(itemData => (
                                    <ArtCard itemData={itemData} />
                                ))}
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel value="5">
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                {AnimationData.map(itemData => (
                                    <AnimationCard itemData={itemData} />
                                ))}
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel value="6">
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                {GameAppData.map(itemData => (
                                    <GameAppCard itemData={itemData} />
                                ))}
                            </Grid>
                        </Box>
                    </TabPanel>
                </TabContext>
            </div>
        </Fade>
    );
}

export default Portfolio;