import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { viewAboutMeEnabled } from '../atoms/Header-Atoms'
import { useRecoilValue } from 'recoil';
import BioShot from '../images/jb-port.png';
import LeftPanelBackground from '../images/about-background-left.svg';

function AboutMe() {
    const enabled = useRecoilValue(viewAboutMeEnabled);

    const ItemLeft = styled(Paper)(({ theme }) => ({
        textAlign: 'left',
        fontSize: 18,
        color: theme.palette.text.secondary,
        minHeight: 500,
        backgroundImage: `url(${LeftPanelBackground})`,
        backgroundSize: "cover"
    }));

    const ItemRight = styled(Paper)(({ theme }) => ({
        minHeight: 500,
        backgroundImage: `url(${BioShot})`,
        backgroundSize: "cover"
    }));

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div id="section-1" className="contentModule">
                <Grid container spacing={2}>
                    <Grid item xs={4} md={6}>
                        <ItemLeft>
                            <div className="leftPanel">
                                <h3>About Me</h3>
                                <p>Hello there, I'm John.</p>
                                <p>In a nutshell, I make things, I'm a Maker. Primarily I work in a digital medium for creating graphic art. I love creating from scratch but can just as easily follow a brief. I use a variety of tools in the Adobe suite but don't limit myself and use what the job requires.</p>
                                <p>I also write code, primarily for websites, but I know lots of languages and pick up others as needed.</p>
                                <p>I absolutely love the beauty of fusing software with art. Outside of creating graphics or coding software I enjoy drawing, sculpting, woodcraft and photography.</p>
                                <p>For a more holistic view of my professional experience please see my <a href="https://www.linkedin.com/in/johnbrickner/" target="_blank"  rel="noreferrer">LinkedIn</a> profile. Thanks for stopping by and let me know if you'd like to work on a project together.</p>
                            </div>
                        </ItemLeft>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <ItemRight></ItemRight>
                    </Grid>
                </Grid>
            </div>
        </Fade>      
    );
}

export default AboutMe;