const AnimationData = [
    {
        videoPath: "/assets/flash-animation/jack-in-box.mp4",
        videoThumb: "/assets/flash-animation/jack-in-box-thumb.png",
        imageTitle: "Jack In The Box"
    },
    {
        videoPath: "/assets/flash-animation/eshare-presentation.mp4",
        videoThumb: "/assets/flash-animation/eshare-presentation-thumb.png",
        imageTitle: "eShare Kiosk Open"
    },
    {
        videoPath: "/assets/flash-animation/boat-monster.mp4",
        videoThumb: "/assets/flash-animation/boat-monster-thumb.png",
        imageTitle: "The Deep"
    },
    {
        videoPath: "/assets/flash-animation/haunted-forest.mp4",
        videoThumb: "/assets/flash-animation/haunted-forest-thumb.png",
        imageTitle: "Haunted Forest"
    },
    {
        videoPath: "/assets/flash-animation/calendar.mp4",
        videoThumb: "/assets/flash-animation/calendar-thumb.png",
        imageTitle: "Calendar"
    },
    {
        videoPath: "/assets/flash-animation/change-application-prototype.mp4",
        videoThumb: "/assets/flash-animation/change-application-prototype-thumb.png",
        imageTitle: "Change.Me Site"
    },
    {
        videoPath: "/assets/flash-animation/change-teaser.mp4",
        videoThumb: "/assets/flash-animation/change-teaser-thumb.png",
        imageTitle: "Change.Me Ad"
    },
    {
        videoPath: "/assets/flash-animation/fire-particle-sample.mp4",
        videoThumb: "/assets/flash-animation/fire-particle-sample-thumb.png",
        imageTitle: "Fire Particles"
    },
    {
        videoPath: "/assets/flash-animation/flower-butterfly.mp4",
        videoThumb: "/assets/flash-animation/flower-butterfly-thumb.png",
        imageTitle: "Butterfly"
    },
    {
        videoPath: "/assets/flash-animation/gears-animation.mp4",
        videoThumb: "/assets/flash-animation/gears-animation-thumb.png",
        imageTitle: "Gears"
    },
    {
        videoPath: "/assets/flash-animation/brickner-particle-sample.mp4",
        videoThumb: "/assets/flash-animation/brickner-particle-sample-thumb.png",
        imageTitle: "Particle Sample"
    },
    {
        videoPath: "/assets/flash-animation/oneil-microsite.mp4",
        videoThumb: "/assets/flash-animation/oneil-microsite-thumb.png",
        imageTitle: "Oneil Printer Ad"
    },
    {
        videoPath: "/assets/flash-animation/random-movement.mp4",
        videoThumb: "/assets/flash-animation/random-movement-thumb.png",
        imageTitle: "Random Prototype"
    },
    {
        videoPath: "/assets/flash-animation/space-door.mp4",
        videoThumb: "/assets/flash-animation/space-door-thumb.png",
        imageTitle: "Space Door"
    },
    {
        videoPath: "/assets/flash-animation/divine-logo-treatment.mp4",
        videoThumb: "/assets/flash-animation/divine-logo-treatment-thumb.png",
        imageTitle: "Divine Logo"
    },
    {
        videoPath: "/assets/flash-animation/eshare-logo-treatment-a.mp4",
        videoThumb: "/assets/flash-animation/eshare-logo-treatment-a-thumb.png",
        imageTitle: "eShare Logo 1"
    },
    {
        videoPath: "/assets/flash-animation/eshare-logo-treatment-b.mp4",
        videoThumb: "/assets/flash-animation/eshare-logo-treatment-b-thumb.png",
        imageTitle: "eShare Logo 2"
    },
    {
        videoPath: "/assets/flash-animation/eshare-logo-treatment-c.mp4",
        videoThumb: "/assets/flash-animation/eshare-logo-treatment-c-thumb.png",
        imageTitle: "eShare Logo 3"
    },
    {
        videoPath: "/assets/flash-animation/long-beach-skate-ad.mp4",
        videoThumb: "/assets/flash-animation/long-beach-skate-ad-thumb.png",
        imageTitle: "Skate Ad"
    },
    {
        videoPath: "/assets/flash-animation/melita-kiosk-splash.mp4",
        videoThumb: "/assets/flash-animation/melita-kiosk-splash-thumb.png",
        imageTitle: "Melita Kiosk"
    },
    {
        videoPath: "/assets/flash-animation/nexsys-kiosk-prototype.mp4",
        videoThumb: "/assets/flash-animation/nexsys-kiosk-prototype-thumb.png",
        imageTitle: "Nexsys Kiosk"
    },
    {
        videoPath: "/assets/flash-animation/oneil-marketing-piece-a.mp4",
        videoThumb: "/assets/flash-animation/oneil-marketing-piece-a-thumb.png",
        imageTitle: "Oneil Cure Ad"
    },
    {
        videoPath: "/assets/flash-animation/oneil-marketing-piece-b.mp4",
        videoThumb: "/assets/flash-animation/oneil-marketing-piece-b-thumb.png",
        imageTitle: "Oneil OC Ad"
    },
    {
        videoPath: "/assets/flash-animation/oneil-marketing-piece-c.mp4",
        videoThumb: "/assets/flash-animation/oneil-marketing-piece-c-thumb.png",
        imageTitle: "Oneil Trade Ad"
    }
]

export default AnimationData;