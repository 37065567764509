import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";

function handleMediaClick(event, imagePathArray) {
    // If array only has one item we are done.
    if ( imagePathArray.length > 0 ) {
        // Store current image name.
        var currentImagePath = event.currentTarget.src;
        var currentImageName = currentImagePath.split('/').pop();

        // Find match in array. If not at end of array cycle to next.
        for ( var i = 0; i < imagePathArray.length; i++ ) {
            var arrayImagePath = imagePathArray[i];
            var arrayImageName = arrayImagePath.split('/').pop();

            if ( currentImageName === arrayImageName ) {
                // See if there is a next item in array, if not cycle to first.
                if ( imagePathArray[i+1] !== undefined ) {
                    event.currentTarget.src = imagePathArray[i+1];
                }
                else {
                    event.currentTarget.src = imagePathArray[0];
                }
                
                break;
            }
        }
    }
}

const ArtCard = ({itemData}) => (
    <Grid item>
        <Card sx={{ maxWidth: 300 }}>
            <CardMedia
                component="img"
                image={itemData.imagePaths[0]}
                alt={itemData.imageAlt}
                className="woodCraftCardMedia"
                onClick={(e) => {
                    handleMediaClick(e, itemData.imagePaths);
                }}
            />
            <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                    {itemData.imageTitle}
                </Typography>
                <Typography variant="body2" color="text.secondary" className='woodCraftCardContentArea'>
                    {itemData.imageContent}
                </Typography>
            </CardContent>
        </Card>
    </Grid>
)

export default ArtCard;