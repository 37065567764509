import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { viewGetInTouchEnabled } from '../atoms/Header-Atoms'
import { useRecoilValue } from 'recoil';
import GetInTouchShot from '../images/riley.jpeg';
import LeftPanelBackground from '../images/about-background-left.svg';
import IconLinkedIn from '../images/social-linkedin.svg';
import IconEmail from '../images/social-email.svg';

function GetInTouch() {
    const enabled = useRecoilValue(viewGetInTouchEnabled);

    const ItemLeft = styled(Paper)(({ theme }) => ({
        textAlign: 'left',
        fontSize: 18,
        color: theme.palette.text.secondary,
        minHeight: 500,
        backgroundImage: `url(${LeftPanelBackground})`,
        backgroundSize: "cover"
    }));

    const ItemRight = styled(Paper)(({ theme }) => ({
        minHeight: 500,
        backgroundImage: `url(${GetInTouchShot})`,
        backgroundSize: "cover"
    }));

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div id="section-3" className="contentModule">
                <Grid container spacing={2}>
                    <Grid item xs={4} md={6}>
                        <ItemLeft>
                            <div className="leftPanel">
                                <h3>Let's Connect</h3>
                                <p>If you are interested in connecting you can reach out below either via email or LinkedIn. I'm open to consulting, contract employment or full time permanent roles.</p>
                                <br />
                                <a href="https://www.linkedin.com/in/johnbrickner/" target="_blank">
                                    <img src={IconLinkedIn} width={100} height={100} className="socialIcon" />
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href="mailto:brixel@gmail.com" target="_blank">
                                    <img src={IconEmail} width={100} height={100} className="socialIcon" />
                                </a>
                            </div>
                        </ItemLeft>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <ItemRight></ItemRight>
                    </Grid>
                </Grid>
            </div>
        </Fade>      
    );
}

export default GetInTouch;