const WoodCraftData = [
    {
        imagePaths: ["/assets/art/moana-a.png", "/assets/art/moana-b.png", "/assets/art/moana-c.png"],
        imageAlt: "moana carving",
        imageTitle: "Moana Carving",
        imageContent: "Moana inspired carving done in a cherry cutting board with a two-tone stain."
    },
    {
        imagePaths: ["/assets/art/leafy-a.png", "/assets/art/leafy-b.png"],
        imageAlt: "leafy baking shelf",
        imageTitle: "Leafy Baked Goods Display",
        imageContent: "Playful character design featuring Leafy with a bench style cherry shelf reinforced with steel rods and copper casings."
    },
    {
        imagePaths: ["/assets/art/cube-a.png", "/assets/art/cube-b.png", "/assets/art/cube-c.png", "/assets/art/cube-d.png", "/assets/art/cube-e.png"],
        imageAlt: "cube box puzzle",
        imageTitle: "Cube",
        imageContent: "Cube is inspired from a 2D drawn character that I recreated as 3D art. The box features a lot of inlay technique and made functional to hold a wood puzzle."
    },
    {
        imagePaths: ["/assets/art/reaper-a.png", "/assets/art/reaper-b.png"],
        imageAlt: "reaper subnautica",
        imageTitle: "Subnautica Reaper",
        imageContent: "Reaper sculpture from the indie classic Subnautica complete with internal led system for a different experience in the dark."
    },
    {
        imagePaths: ["/assets/art/dragonbox-a.png", "/assets/art/dragonbox-b.png"],
        imageAlt: "dragon dice dungeons",
        imageTitle: "D&D Game Box",
        imageContent: "Storage box themed around D&D and dice made from walnut, flame birch and maple. Internal removable tray and original D&D manual resin art."
    },
    {
        imagePaths: ["/assets/art/totoro-a.png", "/assets/art/totoro-b.png"],
        imageAlt: "totoro clock",
        imageTitle: "Totoro Clock",
        imageContent: "Upcycled spice rack turned into a Totoro inspired clock. Stands are magnetically attached for wall hanging if desired."
    },
    {
        imagePaths: ["/assets/art/frameart-a.png"],
        imageAlt: "frame dog",
        imageTitle: "Bugsy",
        imageContent: "Framed photo art of a beloved dog named Bugsy. Made from reclaimed acacia wood flooring and cloth photo art in resin."
    },
    {
        imagePaths: ["/assets/art/kiteshield-a.png", "/assets/art/kiteshield-b.png"],
        imageAlt: "kiteshield",
        imageTitle: "Dragon Kite Shield",
        imageContent: "Kite shield created from panel board, leather and themed with hand painted dragon art."
    },
    {
        imagePaths: ["/assets/art/homepod-a.png", "/assets/art/homepod-b.png"],
        imageAlt: "homepod bamboo leaves",
        imageTitle: "Homepod Bamboo Stand",
        imageContent: "Upcycled bamboo HomePod stand created from a bamboo utensil holder. Carved leaves for easy cord hiding and optional lighting."
    },
    {
        imagePaths: ["/assets/art/nightstand-a.png", "/assets/art/nightstand-b.png"],
        imageAlt: "nightstand furniture",
        imageTitle: "Cherry Nightstands",
        imageContent: "A pair of nightstands created with cherry wood and steel hairpin legs. Finish with tongue oil."
    },
    {
        imagePaths: ["/assets/art/planter-a.png", "/assets/art/planter-b.png"],
        imageAlt: "plants planter box",
        imageTitle: "Butterfly Planter",
        imageContent: "Small planter made from cherry and walnut with butterfly inlays infused with lavender petals that glow in the dark. Custom designed laser cut steel stand."
    },
    {
        imagePaths: ["/assets/art/dragonegg-a.png"],
        imageAlt: "dragon egg scale",
        imageTitle: "Dragon Egg",
        imageContent: "Sculpted scaled dragon egg in wood display stand. Created using air dry clay and custom hand painted."
    },
    {
        imagePaths: ["/assets/art/skullcast-a.png"],
        imageAlt: "d&d skulls candles roleplay",
        imageTitle: "Skull Candle Holders",
        imageContent: "Custom molding and recast of a skull retooled to hold candles. Cast in a fine plaster and hand painted."
    },
    {
        imagePaths: ["/assets/art/puzzle-art-a.png"],
        imageAlt: "wood puzzle art fantasy robot sunset",
        imageTitle: "Sunset",
        imageContent: "Hand drawn art done in Photoshop. This was used for both a printed on canvas painting as well as a wood puzzle."
    },
    {
        imagePaths: ["/assets/art/snowman-a.png"],
        imageAlt: "snowman fright cold",
        imageTitle: "The Snowman",
        imageContent: "Hand drawn piece done in Photoshop."
    },
    {
        imagePaths: ["/assets/art/charlie-a.png"],
        imageAlt: "friend charlie pencil",
        imageTitle: "School Mornings",
        imageContent: "Hand drawn pencil art inspired by morning car rides to school during childhood."
    },
    {
        imagePaths: ["/assets/art/hallow-eve-a.png"],
        imageAlt: "halloween pumpkin",
        imageTitle: "Halloween Stroll",
        imageContent: "Hand drawn piece done in Photoshop."
    },
    {
        imagePaths: ["/assets/art/mobile-game-ella-a.png"],
        imageAlt: "mobile game character ella",
        imageTitle: "Ella",
        imageContent: "Mobile game character inspired art piece done in Animate."
    },
    {
        imagePaths: ["/assets/art/mobile-game-willow-a.png"],
        imageAlt: "mobile game character willow",
        imageTitle: "Willow",
        imageContent: "Mobile game character inspired art piece done in Animate."
    },
    {
        imagePaths: ["/assets/art/the-watcher-a.png"],
        imageAlt: "watcher science fiction",
        imageTitle: "The Watcher",
        imageContent: "Hand drawn piece done in Photoshop."
    },
    {
        imagePaths: ["/assets/art/power-of-learning.png"],
        imageAlt: "eshare power learning",
        imageTitle: "Reader",
        imageContent: "Hand drawn piece done in Paint."
    }
]

export default WoodCraftData;