import Header from "./components/Header"
import Footer from "./components/Footer"
import MainContent from "./components/MainContent"

function App() {
    return (
        <div className="app-container">
            <div className="grid-header">
                <Header />
            </div>
            <div className="grid-main">
                <MainContent />
            </div>
            <div className="grid-footer">
                <Footer />
            </div>
        </div>
    );
}

export default App;