import AboutMe from "./AboutMe"
import Portfolio from "./Portfolio";
import GetInTouch from "./GetInTouch";

function MainContent() {
  return (
    <div className="main-container">
      <AboutMe />
      <Portfolio />
      <GetInTouch />
    </div>
  );
}

export default MainContent;