function Footer() {
    const year =  new Date().getFullYear();

    return (
      <div className="footer-container">
          <small>© {year} John Brickner</small>
      </div>
    );
  }

  export default Footer;